:root{
  --theme-green: #29ffe1;
  --theme-blue: #0084ff;
  --theme-neutral: #12c5f1;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p,
h1,
h2,
span,
ul,
button {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: linear-gradient(80.03deg, #0057FF -89.05%, #00A3FF -14.89%, #32FFDA 61.03%, #07FFFF 119.03%);
  font-family: 'DM Sans', sans-serif;
}

/* .anims{
  opacity: 0.2;
  width: 100px;
  aspect-ratio: 1;
  position: absolute;
  background: magenta;
  border-radius: 50%;
  animation: 10s backgroundAnim forwards 20;
}

.anim1{
  top: 20px;
  left: 40px;
  /* transform: rotateZ(50deg); */
  /* --anim-path: translateX(1000px 20px); 
}

@keyframes backgroundAnim{
  0%{
    /* transform: translateX(0px); 
  }
  100%{
    /* transform: var(--anim-path); 
    transform: translateX(1000px) translateY(20px);
  }
} */
*{
  margin: 0px;
}

/* spherw */
.Homerightsphere {
  position: absolute;
  width: 697px;
  height: 502px;
  right: -550px;
  top: 80%;
  background: rgb(0, 87, 255,0.4);
  filter: blur(200px);
  border-radius: 50%;
}

.container-div {
  
  background-color: #000000;
  color: white;
  width:100%;
  overflow-y: scroll;
  overflow-x: hidden !important;
  height: 100vh;
  font-family: "Prompt", sans-serif;
 
}
.container-div::-webkit-scrollbar{
  width: 5px !important;

}
.container-div::-webkit-scrollbar-track{
  background-color: black;
}
.container-div::-webkit-scrollbar-thumb{
  background: #4e4e4e;
  border-radius: 10px;
}
.header {
  padding: 10px 0px ;
  /* flex-direction: row; */
}

.nav-link{
  font-size: 20px;
}

.header_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_list {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header_list li {
  margin-left: 20px;
  cursor: pointer;
  font-size: 25px;
}

.header_brand {
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.header_brand::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-right: 5px;
  background: var(--background);
}
.desktop_view li:hover{
  background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);

  border-radius: 6px;
} 


/* .connect_wallet { */
  /* background: var(--background);
  padding: 5px 10px;
  color: black;
  font-size: 25px;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(
    90deg,
    #0057ff -36.21%,
    #00a3ff 25.8%,
    #32ffda 89.29%,
    #07ffff 137.78%
  ); */

/* } */
.menuactive{
  display: none;
}
.header .hamburger-react{
  display: none;
}
.content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0rem 3rem;
  margin-bottom: 5rem;
  width: 100%;
}
.homwgif{
  display: none;
}
.sections{
  margin-bottom: 7rem;
}


.main_content .title {
  line-height: 5rem;
  font-size: 70px;
  font-weight: 800;
  width: 100px;
}

.payper{
  /* color: var(--theme-green); */
  /* color: linear-gradient( 90deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78% );; */
  
}
.finance{
  /* color: var(--theme-blue); */
  background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub_title {
  font-size: 20px;
  /* width: 80%; */
}

.main_content .left_content > * {
  margin-bottom: 15px;
}

.left_content {
  width: 45%;
}

.custom_btn {
  /* margin-top: 20px;
  padding: 7px 14px;
  border-radius: 30px;
  color: black;
  text-transform: uppercase;
  font-size: 25px;
  width: max-content;
  background: linear-gradient(
    90deg,
    #0057ff -36.21%,
    #00a3ff 25.8%,
    #32ffda 89.29%,
    #07ffff 137.78%
  );
  font-weight: 700; */
  color: antiquewhite;
  border: 1px solid #72ff5e;
  border-radius: 30px;
  transition: 0.125s linear;
  /* margin-top: 1rem; */
}

.custom_btn:hover{
  color: black;
  background-color: #1cd6ea;
  border-color: #1cd6ea;
  box-shadow: 0px 0px 30px 4px #1cd6ea;
}

.right_content {
  margin-left: 150px;
}

.right_content > img {
  height: 400px;
  filter: opacity(0.4);
}

.about_ecosystem {
  width: 95%;
  margin: auto;
  margin-bottom: 7rem;
}
.about_ecosystem > .join_eco {
  margin-top: 40px;
  /* border: 2px solid; */
  font-size: 60px;
  font-weight: 900;
  width: max-content;
  font-style: normal;
  height: min-content;
  position: static;
}

.join_eco p{
  text-decoration: none;
  background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  
  /* animation: 2s joinEco alternate infinite linear; */
  /* animation: 2s joinEco alternate infinite linear;
  text-shadow: 0px 0px 11px var(--theme-green); */
}

@keyframes joinEco {
  100%{
    text-shadow: 0px 0px 11px var(--theme-blue);
    -webkit-text-stroke: 1px var(--theme-blue);
    text-decoration-color: var(--theme-blue);
  }
}

.chip_container {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: rgba(82, 222, 247, 0.7) rgb(180, 179, 179,0.2); 
  margin-top: 30px;
  overflow-x: scroll;
}
.chip_container::-webkit-scrollbar{
  width: 5px !important;

}
.chip_container::-webkit-scrollbar-track{
  background-color: black;
}
.chip_container::-webkit-scrollbar-thumb{
  background: #4e4e4e;
  border-radius: 25px;
}
.chip_container > ul {
  list-style: none;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 1200px;
  margin: auto;
}

.chip_container > ul > li {
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #333333;
  padding: 10px 25px;
  border-radius: 30px;
}

.learn_more {
  margin-top: 95px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.learn_more > .content {
  width: 375px;
  margin: 0px 5rem;
}

.learn_more > .content > p {
  margin-bottom: 10px;
}

.content > h1{
  text-align: left;
width: 100%;
margin-bottom: 1.2rem;
}

.learn_more > .options {
  width: 630px;
}

.options {
  position: relative;
  z-index: 0;
  margin: auto;
  background-size: cover;
  /* background-image: url("/back.gif"); */
  width: 45%;
}

.options::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
}
/* .learn_more > .learn_more_content {

} */

.back_gif {
  width: 100%;
}

.option_images {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.option_images:nth-child(1) {
  margin-top: 7px;
}
.option_images > .divider {
  display: flex;
  justify-content: space-between;
  width: 61%; 
  margin-top: 81px;
}

.option_enclose{
  width: 100px;
}

.option_images img {
  width: 100px;
  opacity: 0.3;
}

.option_enclose:hover img{
  opacity: 0.5;
  /* animation: 0.5s option_images; */
}

@keyframes option_images {
  100%{
    transform: rotateZ(360deg) rotateY(360deg);
    opacity: 0.3;
  }
}

.why_choose {
  margin-top: 80px;
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 50px;
  text-align: center;
}

.cards {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.card {
  /* padding: 10px 15px; */
  min-width: 150px;
  background-color: #333333;
  border-radius: 12px;
  margin: 1rem auto;
  max-width: 300px;
  height: 300px;
  transition: 0.25s;
}
.card:hover{
border: 1px solid white;
box-shadow: 0px 0px 12px -3px white;
}

.card:hover .card_title{
  font-size: 22px;
}

.card_container {
  padding: 15px 10px;
  width: 250px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.card_title {
  font-size: 20px;
  font-size: 700;
  margin-bottom: 10px;
  transition: 0.25s;
}

.card_content {
  padding: 0 25px;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
}

.cardImg {
  /* width: 100px; */
  height: 100px;
}

.join_discord {
  margin-top: 40px;
  width: 100%;
  background: linear-gradient(
    90deg,
    #0057ff -36.21%,
    #00a3ff 25.8%,
    #32ffda 89.29%,
    #07ffff 137.78%
  );
  border-radius: 12px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.join_discord > .title {
  text-align: center;
  width: 70%;
  color: black;
  font-size: 50px;
}

.join_discord_button {
  color: black;
  padding: 10px 15px;
  width: max-content;
  text-align: center;
  margin-top: 20px;
  border-radius: 30px;
  font-weight: 700;
}
.join_discord_button:hover{
  background: white;
  box-shadow: 0px 0px 30px 4px white;
  border: 0.5px solid white;
}


/* updates /////////////////////////////////////////////////////////////////// */
.payperfproduct{
  width: 100%;
  min-height: 60vh !important;
}




.uses , .upcoming{
  margin: 90px auto;
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.payperfproduct .title {
  font-size: 50px;
  text-align: left;
  width: 650px;
  margin-bottom: 50px;
}

.uses_content {
  flex-basis: 60%;
  padding: 0 40px;

  text-align: left;
}
.uses_image{
  flex-basis: 20%;
}
.uses_image > img {
  max-width: 240px;
  /* margin-left: -70%; */
}
.upcoming_content > .title {
  font-size: 50px;
  text-align: left;
  margin-top: -50px;
  /* width: 500px; */
}

.upcoming_content {
  /* width: 400px; */
  flex-basis: 60%;
  padding: 0 40px;
  text-align: left;
}
.upcoming_image{
  flex-basis: 20%;
}
.upcoming_image > img {
  width: 350px;
  /* margin-left: 20%; */
  /* height: 400px; */
}

.footer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 20px;
  margin-right: 40px; */
  flex-direction: column;
  background: #001e1e;
}

.left_container > ul {
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.left_container > ul > li {
  margin: 44px auto;
  font-weight: 700;
  cursor: pointer;
  padding: 2px 37px;
}

.middle_container {
  display: flex;
  align-items: center;
  margin: 40px 4px;
  width: 15rem;
}

.middle_container > * {
  margin: auto;
  cursor: pointer;
}

.right_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.subscribe {
  display: inline-block;
}

.subscribe > input {
  padding: 10px 15px;
  background: none;
  border: 1px solid white;
  color: white;
  outline: none;
}
.subscribe_btn {
  display: inline-block;
  margin-left: 20px;
  background: none;
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid white;
  color: white;
  cursor: pointer;
}

.token {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.token > img {
  margin-right: 10px;
}

.circles{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  /* overflow: hidden; */
}

.circles li{
  position: relative;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

.outer{
  position: relative;
  left: 190px;
  width: 400px;
  height: 4px;
  transform: translate(-50%, -50%);
}
.item{
  transform: rotateX(70deg);
  transform-style: preserve-3d;
  position: relative;
  top: -272px;
  left: 100px;
}
.seed{
  width: 50px;
  border-radius: 50%;
  aspect-ratio: 1;
  background: #333;
  animation: 6s anim forwards infinite linear;
  margin: auto;
}
@keyframes anim{
  0%{
      transform: rotateZ(0deg) translateY(200px) rotateZ(-0deg) rotateX(-70deg);
      width: 80px;
  }
  50%{
      width: 50px;
  }
  100%{
      transform: rotateZ(360deg) translateY(200px) rotateZ(-360deg) rotateX(-70deg);
      width: 80px;
  }
}

.roadmap{
  width: 80%;
  margin: auto;
}


@media screen and (max-width: 1000px){
  .uses, .upcoming{
    flex-direction: column;
  }
  .upcoming_content{
    text-align: left !important;
  }
  .sub_title{
    font-size: 17px;
  }

  .title{
    font-size: 38px !important;
  }
}

@media screen and (max-width:1200px) and (min-width:766px) {
  .main_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 3rem 1rem;
    margin-bottom: 5rem;
  }
  .main_content .title {
    line-height: normal !important;
  }
  .right_content > img {
    height: 300px;
    filter: opacity(0.4);
  }
  .outer {
    position: relative;
    left: 190px;
    width: 300px;
    height: 4px;
    transform: translate(-80%, -80%);
  }
  .seed {
    min-width: 30px;
    max-width: 50px;
    border-radius: 50%;
    aspect-ratio: 1;
    background: #333;
    animation: 6s anim forwards infinite linear;
    margin: auto;
  }
  .main_content .title {
    line-height: 2rem;

  }

  .about_ecosystem > .join_eco {
  
      margin-top: 40px;
      border: 2px solid;
      font-size: 40px;
      font-weight: 900;
      width: max-content;
      font-style: normal;
      height: min-content;
      position: static;
      border: none;
  }
  .upcoming_image{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .upcoming_image span{
    margin: 0 !important;
    font-size: 50px !important;
  }
  .upcoming_image img{
    width: 150px !important;
  }
  .uses_image > img {
    max-width: 150px;
    /* margin-left: -70%; */
  }
}

@media screen and (max-width:765px) and (min-width:230px) {
  .menuactive{
    display: block;
  }
  .header .hamburger-react{
    display:block;
  }
  .header {
    padding: 0px 0px;
    position: relative;
  }
  .header .header_brand {
    position: relative;
    left: -40%;
    margin-bottom: 0 !important;
  }

  .main_content {
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 0rem 0rem;
    margin-bottom: 5rem;
  }

  .left_content .content {
  
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  
  }
  .left_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
  } 
  .left_content .custom_btn{
    width: 150px !important;
  }
  .left_content .sub_title .title{
    width: auto;
    margin-bottom: 20px;
  }

  .left_content .sub_title{
    text-align: center;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left_content .sub_title p{
    font-size: 25px;
    margin-bottom: 20px;
  }
  .left_content .sub_title .custom_btn{
    height: 32px !important;
    width: 200px !important;
  }

  .left_content .sub_title .custom_btn p{
    font-size: 13px !important;
    width: 200px !important;

  }
  .payper{
    margin-left: 5px;
  }
  .main_content .title {
    width: 200px;

    text-align: center;
    line-height: normal !important;
  }
  .right_content > img {
    height: 300px;
    filter: opacity(0.8);
  }
  .homwgif{
    background-image: url('../../../public/back.gif');
    box-sizing: border-box;
    overflow: hidden;
    margin-top: -6rem;
    display: block;
    height: 150px;
    width: 150%;
    background-position: center;
    background-size: cover;
    opacity: 0.2;
  }

  header{
    margin-bottom: -3rem !important;
  }

  .header .desktop_view {
   display: none;
  }


.menuactive{
  position: absolute !important;
  right: -120px !important;
  top: 50px;
  background: -webkit-linear-gradient( 60deg, rgb(0, 87, 255,0.7) -36.21%, rgb(0, 163, 255,0.7) 25.8%, rgb(50, 255, 218,0.7) 89.29%, rgb(7, 255, 255,0.7) 137.78%);
  border-radius: 4px;
  min-height: 150px;
  width: 120px;
}
.menuactive li{
  margin: 15px 20px;
  font-weight: bold;
}
.menuactive_true{
  right: 10px !important;

}
  header ul a{
    font-size: 14px !important;
  }
  .header_brand {
    font-size: 14px;
  }
  .outer {
    position: relative;
    left: 190px;
    width: 300px;
    height: 4px;
    transform: translate(-80%, -80%);
  }
  .seed {
    min-width: 30px;
    max-width: 50px;
    border-radius: 50%;
    aspect-ratio: 1;
    background: #333;
    animation: 6s anim forwards infinite linear;
    margin: auto;
  }
  .main_content .title {
    line-height: 2rem;
  }
  .join_eco {
    width: 100% !important;
  }
.join_eco p{
  width: 100%;
  text-align: center;
  font-size: 28px;
}
.token{
  padding: 0 !important;
  width: auto;
}
.token p{
  font-size: 12px;
  text-align: left !important;
  width: auto;
}
.token img{
  width: 16px;
}
.chip_container ul{
  width: 900px !important;
  justify-content: unset;
}
.chip_container ul li{
  margin: 5px !important;
  width: auto;
}
  .about_ecosystem > .join_eco {
  
      margin-top: 40px;
      border: 2px solid;
      font-size: 40px;
      font-weight: 900;
      width: max-content;
      font-style: normal;
      height: min-content;
      position: static;
      border: none;
  }
  .upcoming_image{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .upcoming_image span{
    margin: 0 !important;
    font-size: 50px !important;
  }
  .upcoming_image img{
    width: 100px !important;
  }
  .uses_image > img {
    max-width: 150px;
    /* margin-left: -70%; */
  }
  .outer{
    display: none;
  }
  .learn_more > .content {
    width: 200%;
    margin: 0px 0rem;
  }

  .option_enclose img{
    width: 40px;
    margin-left: 10px;
  }
  .option_images > .option_enclose img{
     margin-left: 45px;
  }
  .options{
    background:none !important;
  }
  .option_images{
    margin: 0 !important;
  }
  .content h1{
    margin-left: 12px;
  }
  
.learn_more  .content .sub_title{
  width: 100%;
margin-left: 12px;
  font-size: 11px;
text-align: left;
}
.content button{
  font-size: 12px !important;
  margin-left: 12px;

}
.divider .option_enclose img{
  margin: 0 20px;
}
.why_choose .title >p{
  font-size: 20px;
}
.card{
  background: none;
  padding: 0;
  margin: 0  auto;

}
.sections{
  margin-bottom: 2rem;
}
.card_content{
  font-size: 11px;
  color: 
  rgba(255, 255, 255, 0.8);
}
.join_discord .title p{
  font-size: 20px;
} 
.join_discord_button p {
  font-size: 13px;
}

.upcoming_content > .title {

    font-size: 50px;
    text-align: left;
    margin-top: -50px;
    width: 100%;

}
.payperfproduct {
  min-height: 40vh !important;
}
.payperfproduct .sub_title{
  text-align: center;
} 
.upcoming_content {
  flex-basis: 100% !important;
  padding: 0;
}
.upcoming_content .custom_btn{
  width: 150px;
}
.upcoming_image span{
  font-size: 35px !important;
}
.payperfproduct .title {

    font-size: 50px;
    text-align: left;
    width: 100%;
    margin-bottom: 50px;
    display: none;

}
.uses_content{
  flex-basis: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%;pa */
  padding: 0;
}
.uses_content .custom_btn{
  width: 150px;
}
.uses{
  flex-direction: column-reverse;
}
/* new changes */
.upcoming_content{
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}
.uses_image img{
  width: 100px !important;
  margin-bottom: 20px;
}
.Landing_join > div img {
  width: 250px !important;
  margin-bottom: 40px;
}
.Landing_join footer div>img{
  width: 60px !important;
  height: 60px !important;
  margin-right: -44px;
}
.Landing_join footer div h2{
  font-size: 18px;
}
.hamburger-react{
  position:absolute !important;
  right: 2%;
}
.container, .container-sm {
  max-width: 100%;
}
.Homerightsphere{
  display: none;
}

}


.Landing_join {
    width: 100%;
    min-height: 120vh;
    position: relative;
  }
  .Landing_join > div {
    text-align: center;
  }
  .Landing_join > div h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 130%;
  
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.96;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .Landing_join > div h4 {
    margin: 20px;
  
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #898ca9;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .landingdetails_sphere1 {
    position: absolute;
    display: block;
    left: -150px;
    height: 400px;
    width: 400px;
    background: linear-gradient(180deg, #f406fb 0%, #6340f1 100%);
    filter: blur(300px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .Landing_join > div button {
    margin: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 150%;
    text-align: center;
    color: #565976;
    padding: 5px 20px;
    background: none;
    border: 1px solid  #00a3ff;
    border-radius: 10px;
    transition: color 1s, background 0.5s;
  }
  .Landing_join > div button:hover {
    background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);

    color: white;
  }
  .Landing_join>div img{
    width: 400px;
    margin-bottom: 40px;
  }
  
  .Landing_join footer {
    position: absolute;
    font-family: "Inter";
    bottom: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
  }
  footer{
    height: 30vh;
  }
  .Landing_join footer div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px;
  }
  .Landing_join footer div h2 {
    margin-left: 20px;
  }
  .Landing_join ul {
    border: 1px solid  #00a3ff ;
    border-radius: 10px;
    display: flex;
    position: absolute;
    right: 20px;
  }
  .Landing_join ul:hover{
    background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);
    transition: background 0.5s;
  }
  .Landing_join ul img{
    width: 45px;
    height: 45px;
  }
  .Landing_join ul li {
    list-style: none;
    margin: 7px;
  }
  footer div span{
    font-weight: bolder;
    background: -webkit-linear-gradient( 60deg, #0057ff -36.21%, #00a3ff 25.8%, #32ffda 89.29%, #07ffff 137.78%);
  background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }

@media screen and (max-width: 1100px) and (min-width: 925px) {

  .Landing_join > div h2 {
    font-size: 35px;
  }

  .Landing_join > div button {
    font-size: 20px;
  }

}

@media screen and (max-width: 925px) and (min-width: 766px) {


  .Landing_join {
    min-height: 120vh !important;
    margin-top: 5vh;
  }
  .Landing_join > div h2 {
    font-size: 35px;
  }
  .Landing_join footer{

    max-height: 20vh;
  }
  .Landing_join {
    height: 50vh;
  }

}


@media screen and (max-width: 765px) and (min-width: 530px) {

  .Landing_join {
    min-height: 90vh !important;
    margin-top: 5vh;
  }
  .Landing_join > div button {
    font-size: 10px;
  }
  .Landing_join > div h2 {
    font-size: 18px;
  }
  .Landing_join > div h4{
    font-size: 10px;
  }
  .Landing_join footer{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    max-height: 20vh;
  }
  .Landing_join footer div{
    margin: 10px;
  }
  .Landing_join ul {
    position: static;
    margin-top: 50px;
  }
  .Landing_join ul img{
    width: 20px;
    height: 20px;
  }
  .Landing_join ul li{
    margin: 5px;
  }
  .Landing_join > div{
    margin-bottom: 70px !important;
  }

}


@media screen and (max-width: 530px) and (min-width: 270px) {

 
.Landing_join {
    min-height: 100vh !important;
    margin-top: 5vh;
  }
  .Landing_join > div button {
    font-size: 10px;
  }
  .Landing_join > div h2 {
    font-size: 18px;
  }
  .Landing_join > div h4{
    font-size: 10px;
  }
  .Landing_join footer{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .Landing_join footer div{
    margin: 10px;
  }
  .Landing_join ul {
    position: static;
    margin-top: 50px;
  }
  .Landing_join ul img{
    width: 20px;
    height: 20px;
  }
  .Landing_join ul li{
    margin: 5px;
  }
  .Landing_join > div{
    margin-bottom: 70px !important;
  }

}
